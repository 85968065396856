import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSpecialities } from '../../services/Api';
import styles from './NosArtisans.module.css';

const NosArtisans = () => {
  const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    const fetchSpecialities = async () => {
      const data = await getSpecialities();
      setSpecialities(data);
    };

    fetchSpecialities();
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Faites Confiance à des ARTISANS Vérifiés et Qualifiés</h1>
      <h2 className={styles.subtitle}>
        Nous sélectionnons les meilleurs artisans de votre région pour vous garantir un service de qualité
      </h2>

      {/* Affichage en grille de 4 cartes par ligne */}
      <div className={styles.grid}>
        {specialities.map(speciality => (
          <div key={speciality.id} className={styles.card}>
            <Link to={`/metier/${speciality.id}`}>
              <img
                className={styles.image}
                src={`data:${speciality.mimeType};base64,${speciality.imageMetier}`}
                alt={speciality.metier}
              />
              <div className={styles.metier}>{speciality.metier}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NosArtisans;

import React from 'react';
import styles from './ArtisansCard.module.css';

const ArtisansCard = () => {
  return (
    <section className={styles.artisansCard}>
      <h2 className={styles.title}>FAITES CONFIANCE À DES ARTISANS VÉRIFIÉS ET QUALIFIÉS</h2>
      <p className={styles.subtitle}>
        Nous sélectionnons les meilleurs artisans de votre région pour vous garantir un service de qualité.
      </p>
      <div className={styles.artisanGallery}>
        <div className={styles.artisanImage}>
          <img src="/artisans-plombier.png" alt="Artisan 1" />
          <div className={styles.metierText}>PLOMBIER</div>
        </div>
        <div className={styles.artisanImage}>
          <img src="/artisans-menuisier.png" alt="Artisan 2" />
          <div className={styles.metierText}>MENUISIER</div>
        </div>
        <div className={styles.artisanImage}>
          <img src="/artisans-charpentier.png" alt="Artisan 3" />
          <div className={styles.metierText}>CHARPENTIER</div>
        </div>
        <div className={styles.artisanImage}>
          <img src="/artisans-elec.png" alt="Artisan 4" />
          <div className={styles.metierText}>ELECTRICIEN</div>
        </div>
      </div>
    </section>
  );
};

export default ArtisansCard;

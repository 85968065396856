import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendMailToRegister } from '../../services/Api';
import styles from './Join.module.css';

const Join = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    await sendMailToRegister(formData);

    toast.success('Message envoyé avec succès !');

    setFormData({
      nom: '',
      prenom: '',
      email: '',
      phone: '',
      message: ''
    });
    navigate('/');
  };

  return (
    <div className={styles.joinFormContainer}>
      <h2 className={styles.title}>Rejoignez ProxiTravaux</h2>
      <p className={styles.subtitle}>
        Intégrez un réseau de professionnels qualifiés et développez votre activité grâce à notre plateforme. Bénéficiez
        d&apos;une visibilité accrue et accédez à des projets adaptés à vos compétences.
      </p>
      <ul className={styles.benefitsList}>
        <li>Augmentez votre clientèle avec des projets ciblés.</li>
        <li>Bénéficiez d’un accompagnement personnalisé.</li>
        <li>Rejoignez une communauté d&apos;artisans reconnus.</li>
      </ul>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="prenom">
            Prénom
          </label>
          <input
            type="text"
            id="prenom"
            name="prenom"
            placeholder="Votre prénom"
            value={formData.prenom}
            onChange={handleChange}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="nom">
            Nom
          </label>
          <input
            type="text"
            id="nom"
            name="nom"
            placeholder="Votre nom"
            value={formData.nom}
            onChange={handleChange}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Votre email"
            value={formData.email}
            onChange={handleChange}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="phone">
            Téléphone
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Votre n° de téléphone"
            value={formData.phone}
            onChange={handleChange}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="Votre message"
            className={styles.input}
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <button className={styles.submitButton} type="submit">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default Join;

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import SearchPlaceForGeo from '../SearchPlace/SearchPlaceForGeo';
import SearchSpeciality from '../SearchPlace/SearchSpeciality';
import styles from './IntroductionSection.module.css';

const IntroductionSection = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [openList, setOpenList] = useState(null);

  const navigate = useNavigate();
  const containerRef = useRef(null);

  // Fermer les listes déroulantes si on clique en dehors
  useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpenList(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSecteurSubmit = event => {
    event.preventDefault();
    const path = selectedSuggestion
      ? `/map?lat=${selectedSuggestion.geometry.coordinates[1]}&lon=${selectedSuggestion.geometry.coordinates[0]}`
      : '/map';
    navigate(path);
  };

  const handleSpecialitySubmit = event => {
    event.preventDefault();
    const path = selectedSpeciality ? `/metier/${selectedSpeciality.id}` : '/metiers';
    navigate(path);
  };

  const handleSpecialitySelect = speciality => {
    setSelectedSpeciality(speciality);
    setOpenList(null);
  };

  const handleSearchPlaceSelect = suggestion => {
    setSelectedSuggestion(suggestion);
    setOpenList(null);
  };

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false
  };

  return (
    <section className={styles.introSection}>
      <div className={styles.textContainer} ref={containerRef}>
        <h1 className={styles.mainTitle}>ProxiTravaux</h1>
        <h4 className={styles.introText}>
          TROUVEZ UN ARTISAN DE CONFIANCE PRÈS DE CHEZ VOUS, DÉCOUVREZ DES ARTISANS QUALIFIÉS POUR TOUS VOS PROJETS DE
          CONSTRUCTION, RÉNOVATION, ET AMÉNAGEMENT.
        </h4>
        <div className={styles.buttonContainer}>
          <form className={styles.searchForm} onSubmit={handleSpecialitySubmit}>
            <SearchSpeciality onSelect={handleSpecialitySelect} openList={openList} setOpenList={setOpenList} />
            <button type="submit" className={styles.searchButton}>
              Chercher
            </button>
          </form>
          <form className={styles.searchForm} onSubmit={handleSecteurSubmit}>
            <SearchPlaceForGeo onSelect={handleSearchPlaceSelect} openList={openList} setOpenList={setOpenList} />
            <button type="submit" className={styles.searchButton}>
              Chercher
            </button>
          </form>
        </div>
      </div>
      <div className={styles.carouselContainer}>
        <Slider {...settings}>
          <div>
            <img src="/artisans-image1.png" alt="Artisan 1" className={styles.carouselImage} />
          </div>
          <div>
            <img src="/artisans-image2.png" alt="Artisan 2" className={styles.carouselImage} />
          </div>
          <div>
            <img src="/artisans-image3.png" alt="Artisan 3" className={styles.carouselImage} />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default IntroductionSection;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ServicesSection.module.css';

const ServicesSection = () => {
  const navigate = useNavigate();

  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.mainTitle}>NOS SERVICES : UN ACCOMPAGNEMENT DE A À Z</h2>
      <h3 className={styles.subtitle}>
        Découvrez notre documentation dédiée au monde immobilier : des conseils clairs, des astuces pratiques et des
        articles approfondis pour mieux comprendre et réussir vos projets immobiliers.
      </h3>
      <div className={styles.content}>
        {/* Article 1 */}
        <div className={styles.articleCard}>
          <img
            src="/artisans-image1.png"
            alt="Le Diagnostic de Performance Énergétique (DPE)"
            className={styles.cardImage}
          />
          <h3 className={styles.cardTitle}>Le Diagnostic de Performance Énergétique (DPE)</h3>
          <p className={styles.articleDescription}>
            Découvrez pourquoi le DPE est essentiel pour la gestion et la valorisation de vos biens immobiliers.
          </p>
          <a href="/path-to-dpe-article" className={styles.readMore}>
            Lire l&apos;article
          </a>
        </div>

        {/* Article 2 */}
        <div className={styles.articleCard}>
          <img src="/artisans-image1.png" alt="La location nue" className={styles.cardImage} />
          <h3 className={styles.cardTitle}>La location nue</h3>
          <p className={styles.articleDescription}>
            Apprenez les avantages et les spécificités de la location nue pour optimiser vos revenus fonciers.
          </p>
          <a href="/path-to-location-nue-article" className={styles.readMore}>
            Lire l&apos;article
          </a>
        </div>

        {/* Article 3 */}
        <div className={styles.articleCard}>
          <img src="/artisans-image1.png" alt="La SCI à l'IS" className={styles.cardImage} />
          <h3 className={styles.cardTitle}>La SCI à l&apos;IS</h3>
          <p className={styles.articleDescription}>
            Comprenez les atouts fiscaux de la Société Civile Immobilière soumise à l&apos;impôt sur les sociétés.
          </p>
          <a href="/path-to-sci-article" className={styles.readMore}>
            Lire l&apos;article
          </a>
        </div>

        {/* Article 4 */}
        <div className={styles.articleCard}>
          <img src="/artisans-image1.png" alt="Le LMNP" className={styles.cardImage} />
          <h3 className={styles.cardTitle}>Le LMNP</h3>
          <p className={styles.articleDescription}>
            Découvrez les opportunités offertes par le statut de Loueur Meublé Non Professionnel.
          </p>
          <a href="/path-to-lmnp-article" className={styles.readMore}>
            Lire l&apos;article
          </a>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.moreButton} onClick={() => navigate('/infos-et-aides')}>
          En savoir plus
        </button>
      </div>
    </section>
  );
};

export default ServicesSection;

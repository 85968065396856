import React from 'react';
import { useParams } from 'react-router-dom';
import ArtisanDetail from '../components/ArtisanDetail/ArtisanDetail';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';

const ArtisanDetailPage = () => {
  const { artisanId } = useParams();

  return (
    <div>
      <Header />
      <div className="page-content">
        <ArtisanDetail artisanId={artisanId} />
      </div>
      <Footer />
    </div>
  );
};

export default ArtisanDetailPage;

import { getDistance } from 'geolib';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getArtisansBySpecialiteId, getDetailArtisanByArtisanIdAndType, getSpecialityById } from '../../services/Api';
import SearchPlaceForMetier from '../SearchPlace/SearchPlaceForMetier';
import styles from './Metier.module.css';

const Metier = ({ specialiteId }) => {
  const [artisans, setArtisans] = useState([]);
  const [specialite, setSpecialite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tri, setTri] = useState('pertinence');
  const [userLocation, setUserLocation] = useState(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const cardsPerPage = 10; // Maximum cards per page

  useEffect(() => {
    const initializeData = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        });
      }

      const response = await getArtisansBySpecialiteId(specialiteId);
      const artisansData = response || [];

      const artisansWithImages = await Promise.all(
        artisansData.map(async artisan => {
          const image_principale = await getDetailArtisanByArtisanIdAndType(artisan.id, 'IMG_PRINCIPALE');
          return { ...artisan, image_principale };
        })
      );

      const spe = await getSpecialityById(specialiteId);

      setArtisans(artisansWithImages);
      setSpecialite(spe);
      setLoading(false);
    };

    initializeData();
  }, [specialiteId]);

  // Fonction pour rechercher les coordonnées de l'adresse saisie
  const handleAdresseChange = async event => {
    event.preventDefault();
    const [longitude, latitude] = selectedSuggestion.geometry.coordinates;
    setUserLocation({ latitude, longitude });
  };

  const handleSearchPlaceSelect = suggestion => {
    setSelectedSuggestion(suggestion);
    console.log(suggestion);
  };

  const trierArtisans = () => {
    if (tri === 'localisation' && userLocation) {
      return artisans.sort((a, b) => {
        const distanceA = getDistance(
          { latitude: userLocation.latitude, longitude: userLocation.longitude },
          { latitude: a.latitude, longitude: a.longitude }
        );
        const distanceB = getDistance(
          { latitude: userLocation.latitude, longitude: userLocation.longitude },
          { latitude: b.latitude, longitude: b.longitude }
        );
        return distanceA - distanceB;
      });
    }
    return artisans;
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentArtisans = trierArtisans().slice(indexOfFirstCard, indexOfLastCard);

  const totalPages = Math.ceil(artisans.length / cardsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  if (loading) {
    return <p>Chargement des artisans...</p>;
  }

  if (artisans.length === 0) {
    return <p>Aucun artisan trouvé pour cette spécialité.</p>;
  }

  return (
    <div className={styles.metierContainer}>
      <h2 className={styles.title}>{specialite.metier}</h2>

      <div className={styles.triContainer}>
        <div className={styles.triSelectContainer}>
          <label htmlFor="tri">Trier par:</label>
          <select id="tri" value={tri} onChange={e => setTri(e.target.value)}>
            <option value="pertinence">Pertinence</option>
            <option value="localisation">Localisation</option>
          </select>
        </div>

        <Link to={`/map?metier=${specialiteId}`}>
          <button className={styles.mapButton}>Voir sur la carte</button>
        </Link>
      </div>

      {tri === 'localisation' && (
        <form className={styles.adresseContainer} onSubmit={handleAdresseChange}>
          <label htmlFor="adresse">Code postal ou ville :</label>
          <div>
            <SearchPlaceForMetier onSelect={handleSearchPlaceSelect} />
          </div>
          <button type="submit">Rechercher</button>
        </form>
      )}

      <ul className={styles.artisanList}>
        {currentArtisans.map(artisan => (
          <li key={artisan.id} className={styles.artisanCard}>
            <div className={styles.artisanImage}>
              <Link to={`/artisan/${artisan.id}`}>
                <img
                  src={`data:${artisan.image_principale?.mimeType};base64,${artisan.image_principale?.imageDetail}`}
                  alt={artisan.image_principale?.imageDetail || 'Image indisponible'}
                />
              </Link>
            </div>
            <div className={styles.artisanInfo}>
              <p className={styles.artisanName}>{artisan.entrepriseNom}</p>
              <p>Ville : {artisan.adresse?.ville}</p>
              <p>Rayon d&apos;activité : {artisan.rayonActivite} km</p>
            </div>
          </li>
        ))}
      </ul>

      <div className={styles.pagination}>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Précédent
        </button>
        <span>
          Page {currentPage} sur {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Suivant
        </button>
      </div>
    </div>
  );
};

Metier.propTypes = {
  specialiteId: PropTypes.string.isRequired
};

export default Metier;

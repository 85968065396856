// DetailArtisan.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteDetailArtisan,
  getDetailArtisanByArtisanId,
  postDetailArtisan,
  updateDetailArtisan
} from '../../services/Api';
import DetailArtisanForm from './DetailArtisanForm';

const DetailArtisan = () => {
  const { artisanId } = useParams();
  const [details, setDetails] = useState([]);
  const [newDetail, setNewDetail] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDetails = async () => {
      const response = await getDetailArtisanByArtisanId(artisanId);
      setDetails(response);
    };
    fetchDetails();
  }, [artisanId]);

  // Fonction de validation
  const validateNewDetail = type => {
    const imagePrincipaleCount = details.filter(detail => detail.type === 'IMG_PRINCIPALE').length;
    const imagesSecondairesCount = details.filter(detail => detail.type === 'IMG_REALISATION').length;
    const descriptionCount = details.filter(detail => detail.type === 'DESCRIPTION').length;
    const normeCount = details.filter(detail => detail.type === 'NORME').length;

    if (type === 'IMG_PRINCIPALE' && imagePrincipaleCount >= 1) {
      toast.warn("Vous ne pouvez ajouter qu'une seule image principale.");
      return false;
    }
    if (type === 'IMG_REALISATION' && imagesSecondairesCount >= 10) {
      toast.warn('Vous avez atteint le nombre maximal de 10 images secondaires.');
      return false;
    }
    if (type === 'DESCRIPTION' && descriptionCount >= 1) {
      toast.warn('Vous avez atteint le nombre maximal de 1 descriptions.');
      return false;
    }
    if (type === 'NORME' && normeCount >= 10) {
      toast.warn('Vous avez atteint le nombre maximal de 10 normes.');
      return false;
    }
    return true;
  };

  // Fonction pour sauvegarder ou ajouter un détail
  const handleSaveDetail = async (detail, isNew) => {
    if (!validateNewDetail(detail.type)) return;
    let response = null;
    if (isNew) {
      // Ajouter un nouveau détail
      response = await postDetailArtisan({ ...detail, artisanId });
      setDetails([...details, response]);
      setNewDetail({});
    } else {
      // Mettre à jour un détail existant
      response = await updateDetailArtisan(detail.id, detail);
      setDetails(details.map(d => (d.id === detail.id ? detail : d)));
    }
    response ? toast.success('Détail mis à jour avec succès') : toast.error('Erreur lors de la sauvegarde du détail');
    navigate(`/backoffice/admin/artisan/${artisanId}`);
  };

  const handleDelete = async id => {
    await deleteDetailArtisan(id);
    setDetails(prevDetails => prevDetails.filter(detail => detail.id !== id));
    toast.success('Détail supprimé avec succès');
  };

  return (
    <div>
      <h2>Détails de l&apos;artisan</h2>
      {details.map(detail => (
        <DetailArtisanForm
          key={detail.id || Math.random()}
          detail={detail}
          onDelete={handleDelete}
          onSave={handleSaveDetail}
        />
      ))}

      <h3>Ajouter un nouveau détail</h3>
      <DetailArtisanForm detail={newDetail} onSave={detail => handleSaveDetail(detail, true)} isNew={true} />
    </div>
  );
};

export default DetailArtisan;

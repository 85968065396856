import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteUser, getUsers, postUser, updateUser } from '../../services/Api';
import AddUserForm from './AddUserForm';
import styles from './HomeBackoffice.module.css';

const ListAccount = () => {
  const [users, setUsers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await getUsers();
      setUsers(data);
    };
    fetchUsers();
  }, []);

  const handleAddUser = async newUser => {
    await postUser(newUser);
    const updatedUsers = await getUsers();
    setUsers(updatedUsers);
    setShowPopup(false);
  };

  const handleEditUser = async (id, updatedUser) => {
    await updateUser(id, { id, ...updatedUser });
    const updatedUsers = await getUsers();
    setUsers(updatedUsers);
    setShowPopup(false);
    setCurrentUser(null);
  };

  const deleteUserById = async userId => {
    await deleteUser(userId);
    const updatedUsers = await getUsers();
    setUsers(updatedUsers);
  };

  const openPopupForAdd = () => {
    setCurrentUser(null); // Réinitialise pour l'ajout
    setShowPopup(true);
  };

  const openPopupForEdit = user => {
    setCurrentUser(user); // Définit l'utilisateur à modifier
    setShowPopup(true);
  };

  return (
    <div className={styles.container}>
      <h1>Liste des comptes</h1>

      <Link to="/backoffice/admin/home">
        <button className={styles.buttonList}>Retour à l&apos;accueil</button>
      </Link>

      <button className={styles.buttonList} onClick={openPopupForAdd}>
        + Ajouter un compte
      </button>

      {showPopup && (
        <div className={styles.modalOverlay} onClick={() => setShowPopup(false)}>
          <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
            <h2>{currentUser ? 'Modifier le compte' : 'Ajouter un nouveau compte'}</h2>
            <AddUserForm
              onSubmit={currentUser ? updatedUser => handleEditUser(currentUser.id, updatedUser) : handleAddUser}
              user={currentUser} // Passe l'utilisateur à modifier ou null pour un nouvel utilisateur
            />
            <div className={styles.popupButtons}>
              <button className={styles.buttonList} onClick={() => setShowPopup(false)}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}

      <ul>
        {users.map((user, index) => (
          <li key={index}>
            <div>
              {user.email} ({user.role})
              <div className={styles.buttonContainer}>
                <button className={styles.buttonList} onClick={() => openPopupForEdit(user)}>
                  Modifier
                </button>
                <button className={styles.buttonList} onClick={() => deleteUserById(user.id)}>
                  Supprimer
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListAccount;

import React from 'react';
import styles from './InfosEtAides.module.css';

const InfosEtAides = () => {
  const sections = [
    {
      title: 'Le Diagnostic de Performance Énergétique (DPE)',
      content: (
        <p className={styles.text}>
          Le DPE est un document obligatoire pour toute vente ou location d&apos;un bien immobilier. Il permet
          d&apos;évaluer la consommation énergétique et l&apos;empreinte écologique d&apos;un logement. En savoir plus
          sur le site officiel :{' '}
          <a href="https://www.service-public.fr" target="_blank" rel="noopener noreferrer">
            Service Public - DPE
          </a>
          .
        </p>
      )
    },
    {
      title: 'Aides et subventions',
      content: (
        <p className={styles.text}>
          De nombreuses aides financières sont disponibles pour vos travaux, telles que MaPrimeRénov&apos;,
          l&apos;éco-prêt à taux zéro (éco-PTZ) ou encore les aides des collectivités locales. Consultez les détails ici
          :{' '}
          <a href="https://www.maprimerenov.gouv.fr" target="_blank" rel="noopener noreferrer">
            MaPrimeRénov&apos;
          </a>
          .
        </p>
      )
    },
    {
      title: 'Investir : Nom propre ou personne morale ?',
      content: (
        <p className={styles.text}>
          Vous hésitez entre investir en nom propre ou via une structure (SCI, SARL) ? Chaque option a ses avantages
          fiscaux et ses contraintes. Renseignez-vous auprès d&apos;un expert-comptable.
        </p>
      )
    },
    {
      title: 'Le Loueur en Meublé Non Professionnel (LMNP)',
      content: (
        <p className={styles.text}>
          Le statut LMNP permet de bénéficier d&apos;une fiscalité avantageuse pour les biens loués meublés. Découvrez
          les conditions sur{' '}
          <a href="https://www.impots.gouv.fr" target="_blank" rel="noopener noreferrer">
            impots.gouv.fr
          </a>
          .
        </p>
      )
    },
    {
      title: 'La Société Civile Immobilière (SCI)',
      content: (
        <p className={styles.text}>
          La SCI est un excellent moyen de gérer un patrimoine immobilier à plusieurs. Elle offre des avantages en
          termes de gestion et de transmission. Renseignez-vous ici :{' '}
          <a href="https://www.service-public.fr" target="_blank" rel="noopener noreferrer">
            Service Public - SCI
          </a>
          .
        </p>
      )
    },
    {
      title: 'Location nue ou meublée',
      content: (
        <p className={styles.text}>
          La location nue offre une fiscalité simplifiée, tandis que la location meublée permet de générer des
          rendements plus élevés avec des avantages fiscaux (LMNP, LMP). Informez-vous selon vos besoins.
        </p>
      )
    },
    {
      title: 'Le financement de vos projets',
      content: (
        <p className={styles.text}>
          Explorez les solutions de financement comme le crédit immobilier, le prêt relais ou les subventions publiques.
          N&apos;oubliez pas de comparer les taux entre banques.
        </p>
      )
    },
    {
      title: 'Gestion des travaux',
      content: (
        <p className={styles.text}>
          La gestion des travaux peut être complexe : planification, choix des artisans, suivi de chantier. Faites-vous
          accompagner par un maître d&apos;œuvre pour garantir la réussite de vos projets.
        </p>
      )
    },
    {
      title: 'Les prix de rénovation',
      content: (
        <p className={styles.text}>
          Le coût moyen de rénovation varie selon le type de travaux :
          <ul>
            <li>Rénovation légère : 200 à 500 €/m²</li>
            <li>Rénovation complète : 500 à 1 500 €/m²</li>
            <li>Rénovation haut de gamme : 1 500 €/m² et plus</li>
          </ul>
          Pensez à demander plusieurs devis.
        </p>
      )
    },
    {
      title: 'Actualités du marché',
      content: (
        <p className={styles.text}>
          Restez informé des dernières tendances immobilières, des nouvelles législations et des évolutions des taux
          d&apos;intérêt en consultant des sites spécialisés comme{' '}
          <a href="https://www.seloger.com" target="_blank" rel="noopener noreferrer">
            SeLoger
          </a>
          .
        </p>
      )
    },
    {
      title: 'Bons plans du moment',
      content: (
        <p className={styles.text}>
          Découvrez les opportunités immobilières actuelles : promotions sur les matériaux, aides exceptionnelles et
          autres avantages en partenariat avec nos artisans.
        </p>
      )
    },
    {
      title: 'FAQ',
      content: (
        <p className={styles.text}>
          Retrouvez les réponses aux questions fréquentes sur la gestion des travaux, les aides financières, et bien
          plus encore. Consultez notre FAQ ici.
        </p>
      )
    }
  ];

  return (
    <div className={styles.infosEtAides}>
      <h1 className={styles.titrePrincipal}>Infos et aides</h1>
      <h2 className={styles.subtitle}>Retrouvez toutes les informations utiles pour vos projets immobiliers.</h2>
      <div className={styles.accordion}>
        {sections.map((section, index) => (
          <div className={styles.accordionItem} key={index}>
            <button
              className={styles.accordionTitle}
              onClick={e => {
                const content = e.target.nextElementSibling;
                content.style.display = content.style.display === 'block' ? 'none' : 'block';
              }}
            >
              {section.title}
            </button>
            <div className={styles.accordionContent} style={{ display: 'none' }}>
              {section.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfosEtAides;

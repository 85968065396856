import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getSpecialities } from '../../services/Api';
import styles from './SearchPlace.module.css'; // Assure-toi de créer un fichier CSS si nécessaire

const SearchSpeciality = ({ onSelect, openList, setOpenList }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allSpecialities, setAllSpecialities] = useState([]);

  // Charger toutes les spécialités au montage du composant
  useEffect(() => {
    const fetchSpecialities = async () => {
      setLoading(true);
      const results = await getSpecialities();
      setAllSpecialities(results);
      setSuggestions(results);
      setLoading(false);
    };

    fetchSpecialities();
  }, []);

  const handleInputChange = e => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filtrer les spécialités par rapport à l'entrée utilisateur
    const filtered = allSpecialities.filter(speciality =>
      speciality.metier.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filtered);
  };

  const handleSuggestionClick = speciality => {
    setSearchTerm(speciality.metier);
    setSuggestions([]);
    onSelect(speciality); // Passer la spécialité sélectionnée au parent via `onSelect`
  };

  const handleFocus = () => {
    setOpenList('speciality');
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Spécialité"
        className={styles.searchInput}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleFocus}
      />
      {loading && <div className={styles.loading}>Chargement...</div>}
      {openList === 'speciality' && suggestions.length > 0 && (
        <ul className={styles.suggestionsList}>
          {suggestions.map(speciality => (
            <li key={speciality.id} className={styles.suggestionItem} onClick={() => handleSuggestionClick(speciality)}>
              {speciality.metier}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SearchSpeciality.propTypes = {
  onSelect: PropTypes.func.isRequired,
  setOpenList: PropTypes.func.isRequired,
  openList: PropTypes.string
};

export default SearchSpeciality;

import React from 'react';

import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import NosArtisans from '../components/NosArtisans/NosArtisans';

const ArtisansPage = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <NosArtisans />
      </div>
      <Footer />
    </div>
  );
};

export default ArtisansPage;

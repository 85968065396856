import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Join from '../components/Join/Join';

function JoinPage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Join />
      </div>
      <Footer />
    </div>
  );
}

export default JoinPage;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
    const res = await login({ username, password });
    const expirationTime = Date.now() + 15 * 60 * 1000; // 15 minutes en millisecondes
    if (res && res === 'ROLE_ADMIN') {
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('authExpiration', expirationTime);
      navigate('/backoffice/admin/home');
    } else {
      toast.error("Échec de la connexion : mauvais nom d'utilisateur ou mot de passe.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="username">Nom d&apos;utilisateur:</label>
        <input type="text" id="username" value={username} onChange={e => setUsername(e.target.value)} required />
      </div>
      <div>
        <label htmlFor="password">Mot de passe:</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
          <span onClick={() => setShowPassword(prev => !prev)} style={{ marginLeft: '5px', cursor: 'pointer' }}>
            {showPassword ? '👁️' : '🙈'}
          </span>
        </div>
      </div>
      <button className={styles.buttonList} type="submit">
        Se connecter
      </button>
    </form>
  );
};

export default LoginForm;

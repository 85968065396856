import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getSpecialities } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

const ModalDetailArtisan = ({ isOpen, onClose, artisan }) => {
  const [specialityMap, setSpecialityMap] = useState(new Map());

  useEffect(() => {
    const fetchSpecialities = async () => {
      const data = await getSpecialities();
      const map = new Map(data.map(speciality => [speciality.id, speciality.metier]));
      setSpecialityMap(map);
    };
    fetchSpecialities();
  }, []);

  if (!isOpen || !artisan) return null;

  // Convertir les IDs en noms
  const artisanSpecialities = artisan.specialites ? artisan.specialites.map(id => specialityMap.get(id)) : [];

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h2>Détails de l&apos;artisan</h2>
        <p>
          <strong>Nom de société :</strong> {artisan.entrepriseNom}
        </p>
        <p>
          <strong>Spécialités :</strong>{' '}
          {artisanSpecialities.length > 0 ? artisanSpecialities.join(', ') : 'Non renseignées'}
        </p>
        <p>
          <strong>Rayon d&apos;activité :</strong> {artisan.rayonActivite}
        </p>
        <p>
          <strong>Ville :</strong> {artisan.adresse.ville}
        </p>
        <p>
          <strong>SIRET :</strong> {artisan.siret}
        </p>
        <p>
          <strong>SIREN :</strong> {artisan.siren}
        </p>
        {artisan.anneeExperience && (
          <p>
            <strong>Années d&apos;expérience :</strong> {artisan.anneeExperience}
          </p>
        )}

        <button className={styles.closeBtn} onClick={onClose}>
          Fermer
        </button>
      </div>
    </div>
  );
};

ModalDetailArtisan.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  artisan: PropTypes.shape({
    entrepriseNom: PropTypes.string.isRequired,
    specialites: PropTypes.arrayOf(PropTypes.number),
    rayonActivite: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    siret: PropTypes.string,
    siren: PropTypes.string,
    anneeExperience: PropTypes.string,
    adresse: PropTypes.shape({
      ville: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default ModalDetailArtisan;

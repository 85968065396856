import React from 'react';
import ContactPageForm from '../components/ContactPageForm/ContactForm';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';

const ContactPage = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <ContactPageForm />
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;

import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import HomeBackoffice from './components/Backoffice/HomeBackoffice';
import ListAccount from './components/Backoffice/ListAccount';
import ListArtisan from './components/Backoffice/ListArtisan';
import ListParticulier from './components/Backoffice/ListParticulier';
import ListSpecialite from './components/Backoffice/ListSpecialite';
import LoginForm from './components/Backoffice/LoginForm';
import PrivateRoute from './components/Backoffice/PrivateRoute';
import ArtisansPage from './pages/ArtisansPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import MetierPage from './pages/MetierPage';
import NotFound from './pages/NotFound';
import ProposPage from './pages/ProposPage'; // Importation de ProposPage

import DetailArtisan from './components/Backoffice/DetailArtisan';
import ArtisanDetailPage from './pages/ArtisanDetailPage';
import InfosEtAidesPage from './pages/InfosEtAidesPage';
import JoinPage from './pages/JoinPage';
import MapPage from './pages/MapPage';
import './styles/global.css'; // ou le nom de ton fichier CSS principal

const App = () => (
  <>
    <ToastContainer
      position="top-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      pauseOnHover
      draggable
      pauseOnFocusLoss
      theme="light"
    />
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<ProposPage />} />
        <Route path="/metier/:specialiteId" element={<MetierPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/metiers" element={<ArtisansPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/join" element={<JoinPage />} />
        <Route path="/infos-et-aides" element={<InfosEtAidesPage />} />
        <Route path="/artisan/:artisanId" element={<ArtisanDetailPage />} />
        <Route path="/backoffice" element={<LoginForm />} />
        <Route path="/backoffice/admin/*" element={<PrivateRoute />}>
          <Route path="home" element={<HomeBackoffice />} />
          <Route path="artisans" element={<ListArtisan />} />
          <Route path="artisan/:artisanId" element={<DetailArtisan />} />
          <Route path="specialites" element={<ListSpecialite />} />
          <Route path="particuliers" element={<ListParticulier />} />
          <Route path="accounts" element={<ListAccount />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </>
);

export default App;

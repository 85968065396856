import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.mainFooter}>
        <div className={styles.companyInfo}>
          <img src="/logo.png" alt="Logo ProxiTravaux" className={styles.logo} />
          <p>Nous vous accompagnons dans tous vos projets avec des solutions simples et efficaces.</p>
          <p>ProxiTravaux, 2024</p>
        </div>
        <div className={styles.linksContainer}>
          <div className={styles.linksColumn}>
            <h4>Plateforme</h4>
            <ul>
              <li>
                <a href="#">SiteMap</a>
              </li>
              <li>
                <a href="#">Nos Services</a>
              </li>
              <li>
                <a href="#">Témoignages</a>
              </li>
            </ul>
          </div>
          <div className={styles.linksColumn}>
            <h4>Entreprise</h4>
            <ul>
              <li>
                <a href="#">À propos</a>
              </li>
              <li>
                <a href="#">Partenaires</a>
              </li>
            </ul>
          </div>
          <div className={styles.linksColumn}>
            <h4>Ressources</h4>
            <ul>
              <li>
                <a href="#">Métiers</a>
              </li>
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="#">FAQ</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.bottomFooter}>
        <p>© 2024 ProxiTravaux. Tous droits réservés.</p>
        <div className={styles.legalLinks}>
          <a href="#">Conditions d&apos;utilisation</a>
          <a href="#">Politique de confidentialité</a>
          <a href="#">Cookies</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

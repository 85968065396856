import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { FaLocationArrow } from 'react-icons/fa';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getArtisans, getSpecialities } from '../../services/Api';
import ArtisanPopup from './ArtisanPopup';
import styles from './LeafletMap.module.css';
import RecenterAutomatically from './RecenterAutomatically';

// Icône pour les artisans si nécessaire
const artisanIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const LeafletMap = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [artisans, setArtisans] = useState([]);
  const [filteredArtisans, setFilteredArtisans] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const hasShownToast = useRef(false); // On n'affiche pas le toast plusieurs fois même si on passe plusieurs fois par le useEffect
  const lat = queryParams.get('lat');
  const lon = queryParams.get('lon');
  const [center, setCenter] = useState([45.150002, 1.53333]); // Coordonnées par défaut de Brive

  const filteredSpecialities = specialities.filter(
    speciality =>
      speciality.metier.toLowerCase().includes(searchTerm.toLowerCase()) && speciality.id !== selectedSpeciality
  );

  useEffect(() => {
    const fetchArtisansAndSpe = async () => {
      const artisansRes = await getArtisans(); // Appel API pour obtenir les artisans
      setArtisans(artisansRes);
      const specialities = await getSpecialities();
      setSpecialities(specialities);

      const metierFromQuery = queryParams.get('metier');
      if (metierFromQuery) {
        setSelectedSpeciality(parseInt(metierFromQuery));
        // Filtre les artisans en fonction de la spécialité sélectionnée
        const filteredArtisans = artisansRes.filter(artisan => artisan.specialites.includes(parseInt(metierFromQuery)));
        setFilteredArtisans(filteredArtisans);
      } else {
        setFilteredArtisans(artisansRes);
      }
    };

    fetchArtisansAndSpe();
    setLocationBasedOnSearch();
  }, [lat, lon]);

  const setLocationBasedOnSearch = () => {
    if (lat && lon) {
      setCenter([parseFloat(lat), parseFloat(lon)]);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setCenter([latitude, longitude]); // Centre sur la localisation de l’utilisateur
        },
        error => {
          if (!hasShownToast.current) {
            toast.info('La géolocalisation est impossible');
            hasShownToast.current = true;
          }
          console.error('Erreur de localisation :', error);
        }
      );
    }
  };

  const recenterMapOnLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setCenter([latitude, longitude]);
        },
        error => {
          toast.info('La géolocalisation est impossible');
          console.error('Erreur de localisation :', error);
        }
      );
    }
  };

  const handleSpecialityChange = id => {
    const isDeselection = selectedSpeciality === id;
    isDeselection ? setSelectedSpeciality(null) : setSelectedSpeciality(id);
    // Filtre les artisans en fonction de la spécialité sélectionnée
    const filteredArtisans = artisans.filter(artisan => isDeselection || artisan.specialites.includes(id));
    setFilteredArtisans(filteredArtisans);
  };

  return (
    <>
      <div className={styles.specialityContainer}>
        <input
          type="text"
          placeholder="Recherche par corps de métier..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
        <div className={styles.listSpeciality}>
          {selectedSpeciality && (
            <button
              className={`${styles.specialityButton} ${styles.selected}`}
              onClick={() => handleSpecialityChange(selectedSpeciality)} // Permettre de désélectionner
            >
              {specialities.find(s => s.id === selectedSpeciality)?.metier.toUpperCase()}
            </button>
          )}
          {filteredSpecialities.map(speciality => (
            <button
              key={speciality.id}
              onClick={() => {
                handleSpecialityChange(speciality.id);
              }}
              className={`${styles.specialityButton} ${selectedSpeciality === speciality.id ? styles.selected : ''}`}
            >
              {speciality.metier.toUpperCase()}
            </button>
          ))}
        </div>
      </div>

      <div className={styles.premiereDiv}>
        <div className={styles.mapContainer}>
          <MapContainer
            center={center}
            zoom={10}
            style={{ height: '100%', width: '100%' }}
            key={JSON.stringify(filteredArtisans)}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* Utilisation du composant pour recentrer dynamiquement */}
            <RecenterAutomatically center={center} />
            <MarkerClusterGroup>
              {filteredArtisans.map(artisan => (
                <Marker key={artisan.id} position={[artisan.latitude, artisan.longitude]} icon={artisanIcon}>
                  <Popup>
                    <ArtisanPopup artisan={artisan} />
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          </MapContainer>
          {/* Bouton pour recentrer sur la localisation actuelle */}
          <button className={styles.locationButton} onClick={recenterMapOnLocation}>
            <FaLocationArrow size={20} />
          </button>
        </div>
      </div>
    </>
  );
};

export default LeafletMap;

import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Propos from '../components/Propos/Propos';

const ProposPage = () => {
  console.log('ProposPage component is rendering'); // Ajout de débogage

  return (
    <div>
      <Header />
      <div className="page-content">
        <Propos />
      </div>
      <Footer />
    </div>
  );
};

export default ProposPage;

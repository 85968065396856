import React from 'react';
import styles from './Propos.module.css';
import { Link } from 'react-router-dom';

const Propos = () => {
  return (
    <section className={styles.proposSection}>
      <h1 className={styles.title}>À propos de ProxiTravaux</h1>

      {/* Mission */}
      <div className={styles.missionSection}>
        <h2 className={styles.subtitle}>Notre Mission</h2>
        <p className={styles.text}>
          Chez ProxiTravaux, notre mission est de transformer la manière dont les artisans et les clients se connectent,
          facilitant l&apos;accès à des services de qualité en matière de rénovation, de construction et
          d&apos;aménagement. Nous croyons en une mise en relation simple et efficace, qui répond aux besoins
          spécifiques des particuliers tout en permettant aux artisans de développer leur activité et leur réputation.
          En créant une plateforme sans frais supplémentaires pour les artisans ou clients, nous mettons à la portée de
          tous un service de confiance qui permet de simplifier chaque projet, petit ou grand.
        </p>
      </div>

      {/* Expertise */}
      <div className={styles.expertiseSection}>
        <h2 className={styles.subtitle}>Notre Expertise</h2>
        <p className={styles.text}>
          Forts d&apos;une connaissance approfondie des besoins spécifiques du secteur du bâtiment et de
          l&apos;artisanat, nous avons conçu une plateforme innovante, pensée pour le marché d&apos;aujourd&apos;hui.
          Nos outils de mise en relation sont optimisés pour répondre aux standards du marché et aux attentes de nos
          utilisateurs. En parallèle, notre équipe travaille en continu pour offrir une expérience fluide, pour les
          artisans et pour les particuliers.
        </p>
        <p className={styles.text}>
          Notre objectif ? Devenir la référence des sites de mise en relation dans l&apos;artisanat en France, en
          bâtissant une communauté solide et durable où chacun trouve sa place.
        </p>
        <p className={styles.text}>
          Rejoignez-nous pour faire partie d&apos;un écosystème en pleine croissance, orienté vers l&apos;avenir de
          l&apos;artisanat, de la construction et de la rénovation en France !
        </p>
      </div>

      {/* Valeurs */}
      <div className={styles.valuesSection}>
        <h2 className={styles.subtitle}>Nos Valeurs</h2>
        <p className={styles.text}>
          <strong>Transparence :</strong> La transparence est au cœur de notre engagement. ProxiTravaux s&apos;engage à
          offrir des informations claires et complètes sur chaque artisan : leur expérience, les avis de leurs clients,
          et leurs réalisations. Notre but est de vous fournir un maximum de visibilité sur les professionnels afin que
          vous puissiez faire des choix éclairés et en toute confiance.
        </p>
        <p className={styles.text}>
          <strong>Proximité :</strong> Nous croyons en une proximité authentique avec nos utilisateurs, qu&apos;ils
          soient artisans ou clients. En construisant une plateforme axée sur les besoins locaux, nous favorisons des
          connexions directes entre les particuliers et les artisans de leur région.
        </p>
        <p className={styles.text}>
          <strong>Respect et Qualité :</strong> Chaque projet de construction ou de rénovation est unique et mérite une
          attention particulière. Nous sélectionnons des artisans respectueux de leurs engagements, qui placent la
          qualité et le respect des délais au premier plan.
        </p>
      </div>

      {/* Histoire */}
      <div className={styles.historySection}>
        <h2 className={styles.subtitle}>L&apos;Histoire de ProxiTravaux</h2>
        <p className={styles.text}>
          En 2024, quatre passionnés de l&apos;immobilier ont décidé de transformer un besoin en solution en créant
          Proxi Travaux. Ils ont conçu ProxiTravaux pour simplifier la recherche et la mise en relation entre artisans
          et particuliers. Nous croyons également au potentiel du secteur, notamment en raison des nouvelles normes
          énergétiques qui imposent des rénovations majeures à de nombreux biens immobiliers en France.
        </p>
        <p className={styles.text}>
          Grâce à ProxiTravaux, notre but est simple : offrir une plateforme fiable pour des projets réussis, mettre en
          avant des artisans de qualité, et offrir aux particuliers un accès direct aux professionnels compétents.
        </p>
      </div>

      {/* Équipe */}
      <div className={styles.teamSection}>
        <h2 className={styles.subtitle}>Notre Équipe</h2>
        <p className={styles.text}>
          L&apos;équipe de ProxiTravaux est composée de spécialistes passionnés et expérimentés, issus de divers
          domaines : du digital, de la relation client et du bâtiment. Grâce à cette expertise croisée, nous nous
          engageons à offrir une expérience optimale aux particuliers, tout en répondant aux exigences de qualité et de
          fiabilité du secteur du bâtiment.
        </p>
      </div>

      {/* Appel à l'action */}
      <div className={styles.joinSection}>
        <Link to="/join" className={styles.joinButton}>
          Nous Rejoindre
        </Link>
      </div>
    </section>
  );
};

export default Propos;

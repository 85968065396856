import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteSpecialite, getSpecialities, postSpecialite, updateSpecialite } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

import AddSpecialityForm from './AddSpecialityForm';

const ListSpecialite = () => {
  const [specialities, setSpecialities] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // État pour contrôler l'affichage de la popup

  const fetchSpecialities = async () => {
    const data = await getSpecialities();
    setSpecialities(data);
  };

  useEffect(() => {
    fetchSpecialities();
  }, []);

  const handleSubmit = async formData => {
    if (formData.id) {
      await updateSpecialite(formData.id, formData);
    } else {
      await postSpecialite(formData);
    }
    fetchSpecialities();
    setShowPopup(false);
    setSelectedSpeciality(null);
  };

  const handleDelete = async specialityId => {
    await deleteSpecialite(specialityId);
    fetchSpecialities();
  };

  const handleEdit = speciality => {
    setSelectedSpeciality(speciality);
    setShowPopup(true);
  };

  const filteredSpecialities = specialities.filter(speciality =>
    speciality.metier.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <h1>Liste des Spécialités</h1>

      {/* Bouton pour revenir à la page d'accueil */}
      <Link to="/backoffice/admin/home">
        <button className={styles.buttonList}>Retour à l&apos;accueil</button>
      </Link>

      <button
        className={styles.buttonList}
        onClick={() => {
          setSelectedSpeciality(null);
          setShowPopup(true);
        }}
      >
        + Ajouter une Spécialité
      </button>

      {showPopup && (
        <div className={styles.modalOverlay} onClick={() => setShowPopup(false)}>
          <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
            <h2>{selectedSpeciality ? 'Modifier Spécialité' : 'Ajouter une Nouvelle Spécialité'}</h2>
            <AddSpecialityForm onSubmit={handleSubmit} speciality={selectedSpeciality} />
            <div className={styles.popupButtons}>
              <button className={styles.buttonList} onClick={() => setShowPopup(false)}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}

      <input
        type="text"
        placeholder="Rechercher une spécialité..."
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        style={{ margin: '20px 0', padding: '10px', width: '100%' }}
      />

      {filteredSpecialities.length > 0 ? (
        <ul>
          {filteredSpecialities.map(speciality => (
            <li key={speciality.id}>
              {speciality.metier}
              <div className={styles.buttonContainer}>
                <button className={styles.buttonList} onClick={() => handleEdit(speciality)}>
                  Modifier
                </button>
                <button
                  className={styles.buttonList}
                  onClick={() => handleDelete(speciality.id)}
                  style={{ marginLeft: '10px' }}
                >
                  Supprimer
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>Aucune spécialité disponible pour l&apos;instant.</p>
      )}
    </div>
  );
};

export default ListSpecialite;

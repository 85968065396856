import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const authExpiration = localStorage.getItem('authExpiration');

  if (isAuthenticated && authExpiration) {
    const now = Date.now();
    if (now > authExpiration) {
      // Authentification expirée, effacer
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('authExpiration');
      return <Navigate to="/backoffice" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/backoffice" />;
  }
};

export default PrivateRoute;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendMailToSupport } from '../../services/Api';
import styles from './ContactForm.module.css';

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    role: 'Particulier', // Par défaut
    message: ''
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const emailDto = {
      nom: formValues.name,
      email: formValues.email,
      tel: formValues.phone,
      role: formValues.role,
      message: formValues.message
    };

    await sendMailToSupport(emailDto);

    toast.success('Message envoyé avec succès !');
    setFormValues({
      name: '',
      email: '',
      phone: '',
      role: 'Particulier',
      message: ''
    });
  };

  return (
    <section className={styles.contactSection}>
      <div className={styles.textContainer}>
        <h1 className={styles.title}>PROXI TRAVAUX</h1>
        <p>
          Besoin de plus d’informations ? Remplissez le formulaire ci-contre pour contacter notre équipe. Nous sommes à
          votre disposition pour répondre à toutes vos questions et pour vous aider à trouver l’artisan parfait pour
          votre projet.
        </p>
        <div className={styles.socialMediaContainer}>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src="/facebook2.png" alt="Facebook" className={styles.socialMediaIcon} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <img src="instagram.png" alt="Twitter" className={styles.socialMediaIcon} />
          </a>
        </div>
      </div>

      <div className={styles.formContainer}>
        <h2 className={styles.formTitle}>CONTACTEZ NOUS</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <input
            type="text"
            id="name"
            placeholder="Votre nom"
            name="name"
            data-testid="name-input"
            value={formValues.name}
            onChange={handleInputChange}
            className={styles.input}
            required
          />
          <input
            type="email"
            id="email"
            placeholder="Votre email"
            name="email"
            data-testid="email-input"
            value={formValues.email}
            onChange={handleInputChange}
            className={styles.input}
            required
          />
          <input
            type="tel"
            id="phone"
            placeholder="Votre téléphone"
            name="phone"
            data-testid="tel-input"
            value={formValues.phone}
            onChange={handleInputChange}
            className={styles.input}
            required
          />
          <select name="role" value={formValues.role} onChange={handleInputChange} className={styles.input} required>
            <option value="Particulier">Particulier</option>
            <option value="Professionnel">Professionnel</option>
          </select>
          <textarea
            id="message"
            placeholder="Votre message"
            name="message"
            data-testid="mess-input"
            value={formValues.message}
            onChange={handleInputChange}
            className={styles.input}
            required
          ></textarea>
          <button type="submit" className={styles.submitButton}>
            ENVOYER LE FORMULAIRE
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;

import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import InfosEtAides from './../components/InfosEtAides/InfosEtAides';

function InfosEtAidesPage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <InfosEtAides />
      </div>
      <Footer />
    </div>
  );
}

export default InfosEtAidesPage;

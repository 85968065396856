import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import {
  getArtisanById,
  getDetailArtisanByArtisanId,
  getNotesByArtisanId,
  getSpecialityByArtisanId,
  getUserById
} from '../../services/Api';
import styles from './ArtisanDetail.module.css';

// Composant Modal pour afficher les détails des avis
const Modal = ({ isOpen, closeModal, notes }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h2>Tous les Avis</h2>
        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
        <ul className={styles.notesList}>
          {notes.map(note => (
            <li key={note.id} className={styles.noteItem}>
              <ReactStars count={5} value={note.nombreEtoiles || 5} size={24} edit={false} activeColor="#ffd700" />
              <p>
                <strong>Commentaire:</strong> {note.commentaire}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ArtisanDetail = ({ artisanId }) => {
  const [artisan, setArtisan] = useState(null);
  const [user, setUser] = useState(null);
  const [specialites, setSpecialites] = useState([]);
  const [details, setDetails] = useState([]);
  const [notes, setNotes] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [description, setDescription] = useState(null);
  const [secondaryImages, setSecondaryImages] = useState([]);
  const [normeImages, setNormeImages] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [averageRating, setAverageRating] = useState(0);

  const [secondaryPhotoIndex, setSecondaryPhotoIndex] = useState(-1);
  const [normePhotoIndex, setNormePhotoIndex] = useState(-1);

  useEffect(() => {
    const initializeData = async () => {
      const arts = await getArtisanById(artisanId);
      const use = await getUserById(arts.userId);
      const spes = await getSpecialityByArtisanId(artisanId);
      const dets = await getDetailArtisanByArtisanId(artisanId);
      const nots = await getNotesByArtisanId(artisanId);

      setArtisan(arts);
      setUser(use);
      setSpecialites(spes);
      setDetails(dets);
      setNotes(nots);

      if (nots.length > 0) {
        const totalRating = nots.reduce((acc, note) => acc + note.nombreEtoiles, 0);
        setAverageRating(totalRating / nots.length);
      }
    };

    initializeData();
  }, [artisanId]);

  useEffect(() => {
    const fetchUser = async () => {
      if (artisan && artisan.userId) {
        const use = await getUserById(artisan.userId);
        setUser(use);
      }
    };

    fetchUser();
  }, [artisan]);

  useEffect(() => {
    if (details.length > 0) {
      setMainImage(details.find(detail => detail.type === 'IMG_PRINCIPALE'));
      setDescription(details.find(detail => detail.type === 'DESCRIPTION'));
      setSecondaryImages(details.filter(detail => detail.type === 'IMG_REALISATION'));
      setNormeImages(details.filter(detail => detail.type === 'NORME'));
    }
  }, [details]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={styles.cvContainer}>
      {/* Affichage d'un message de chargement tant que artisan n'est pas défini */}
      {!artisan ? (
        <div>Chargement des données de l&apos;artisan...</div>
      ) : (
        <>
          {/* En-tête avec image principale et informations de base */}
          <header className={styles.header}>
            <div className={styles.headerLine}>
              {mainImage && (
                <img
                  src={`data:${mainImage.mimeType};base64,${mainImage.imageDetail}`}
                  alt="Présentation de l'artisan"
                  className={styles.mainImage}
                />
              )}
              <h1 className={styles.entrepriseNom}>{artisan.entrepriseNom}</h1>
            </div>
            <div className={styles.basicInfo}>
              {artisan.anneeExperience && (
                <p>
                  <strong>Années d&apos;expérience:</strong> {artisan.anneeExperience}
                </p>
              )}
              {user.personnalDataToShow && (
                <>
                  {(user.personnalDataToShow === 'TEL' || user.personnalDataToShow === 'ALL') && user.telephone && (
                    <p>
                      <strong>Téléphone:</strong> {user.telephone}
                    </p>
                  )}
                  {(user.personnalDataToShow === 'MAIL' || user.personnalDataToShow === 'ALL') && user.email && (
                    <p>
                      <strong>Email :</strong> {user.email}
                    </p>
                  )}
                </>
              )}

              {/*<p><strong>Email:</strong> <a href={`mailto:${artisan.email}`} className={styles.email}>{artisan.email}</a></p>*/}
              <p>
                <strong>SIRET:</strong> {artisan.siret}
              </p>
              <p>
                <strong>SIREN:</strong> {artisan.siren}
              </p>
            </div>
          </header>

          {/* Adresse et Rayon d'activité */}
          <section className={styles.locationInfo}>
            <p>
              <strong>Adresse:</strong> {artisan.adresse.rue}, {artisan.adresse.codePostal} {artisan.adresse.ville},{' '}
              {artisan.adresse.pays}
            </p>
            <p>
              <strong>Rayon d&apos;activité:</strong> {artisan.rayonActivite} km
            </p>
          </section>

          {/* Note moyenne */}
          {averageRating > 0 && (
            <section className={styles.ratingSection}>
              <h2>Note Moyenne</h2>
              {/* Afficher les étoiles avec la note moyenne */}
              <ReactStars
                count={5}
                value={averageRating || 5}
                size={24}
                isHalf={true}
                edit={false} // L'utilisateur ne peut pas modifier la note
                activeColor="#ffd700" // Couleur des étoiles
              />
              <p>{averageRating.toFixed(1)} / 5</p>
              <button className={styles.viewAllReviewsButton} onClick={toggleModal}>
                Voir tous les avis
              </button>
            </section>
          )}

          {/* Description de l'artisan */}
          {description && (
            <section className={styles.descriptionSection}>
              <h2>À propos de l&apos;artisan</h2>
              <p>{description.description}</p>
            </section>
          )}

          {/* Spécialités */}
          <section className={styles.specialties}>
            <h2>Spécialités</h2>
            <ul>
              {specialites.map(specialite => (
                <li key={specialite.id}>{specialite.metier}</li>
              ))}
            </ul>
          </section>

          {/* Normes Acquises */}
          {normeImages.length > 0 && (
            <section className={styles.certifications}>
              <h2>Normes Acquises</h2>
              <div className={styles.certificationImages}>
                {normeImages.map((imageDetail, index) => (
                  <img
                    key={imageDetail.id}
                    src={`data:${imageDetail.mimeType};base64,${imageDetail.imageDetail}`}
                    alt="Certification norme"
                    className={styles.certificationImage}
                    onClick={() => setNormePhotoIndex(index)}
                  />
                ))}
              </div>
            </section>
          )}

          {/* Galerie de projets précédents */}
          {secondaryImages.length > 0 && (
            <section className={styles.portfolio}>
              <h2>Galerie de Réalisations</h2>
              <div className={styles.portfolioImages}>
                {secondaryImages.map((imageDetail, index) => (
                  <img
                    key={imageDetail.id}
                    src={`data:${imageDetail.mimeType};base64,${imageDetail.imageDetail}`}
                    alt="Réalisation de l'artisan"
                    className={styles.portfolioImage}
                    onClick={() => setSecondaryPhotoIndex(index)}
                  />
                ))}
              </div>
            </section>
          )}
        </>
      )}
      {/* Affichage de la popup avec les détails des avis */}
      <Modal isOpen={isModalOpen} closeModal={toggleModal} notes={notes} />

      {/* Lightboxs pour la galerie d'images */}
      <Lightbox
        open={!!secondaryImages[secondaryPhotoIndex]}
        close={() => setSecondaryPhotoIndex(-1)}
        slides={secondaryImages.map(image => ({
          src: `data:${image.mimeType};base64,${image.imageDetail}`,
          alt: image.type || "Image de l'artisan"
        }))}
        index={secondaryPhotoIndex}
        onIndexChange={setSecondaryPhotoIndex}
      />

      <Lightbox
        open={!!normeImages[normePhotoIndex]}
        close={() => setNormePhotoIndex(-1)}
        slides={normeImages.map(image => ({
          src: `data:${image.mimeType};base64,${image.imageDetail}`,
          alt: image.type || "Image de l'artisan"
        }))}
        index={normePhotoIndex}
        onIndexChange={setNormePhotoIndex}
      />
    </div>
  );
};

ArtisanDetail.propTypes = {
  artisanId: PropTypes.string.isRequired
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      commentaire: PropTypes.string.isRequired,
      nombreEtoiles: PropTypes.number.isRequired
    })
  ).isRequired
};

export default ArtisanDetail;

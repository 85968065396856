// SearchPlace.jsx
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getSuggestionsFromDataGouv } from '../../services/Client';
import styles from './SearchPlace.module.css'; // Assure-toi de bien avoir un fichier CSS pour le style

const SearchPlaceForGeo = ({ onSelect, openList, setOpenList }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = e => {
    const value = e.target.value;
    setSearchTerm(value);

    // Ne pas chercher si l'entrée est trop courte
    if (value.trim().length < 3) {
      setSuggestions([]);
      return;
    }

    // Debounce pour éviter les appels excessifs
    clearTimeout(window.fetchTimeout);
    window.fetchTimeout = setTimeout(async () => {
      setLoading(true);
      const results = await getSuggestionsFromDataGouv(value);
      setSuggestions(results);
      setLoading(false);
    }, 300);
  };

  const handleSuggestionClick = suggestion => {
    setSearchTerm(
      suggestion.properties.label.split(' ').length === 1
        ? suggestion.properties.label + ', ' + suggestion.properties.postcode
        : suggestion.properties.label
    );
    setSuggestions([]);
    onSelect(suggestion); // Passer la suggestion sélectionnée au parent via `onSelect`
  };

  const handleFocus = () => {
    setOpenList('place'); // Active la liste "place"
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Secteur géographique"
        className={styles.searchInput}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleFocus}
      />
      {loading && <div className={styles.loading}>Chargement...</div>}
      {openList === 'place' && suggestions.length > 0 && (
        <ul className={styles.suggestionsList}>
          {suggestions.map(suggestion => (
            <li
              key={suggestion.properties.id}
              className={styles.suggestionItem}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.properties.label.split(' ').length === 1
                ? suggestion.properties.label + ', ' + suggestion.properties.postcode
                : suggestion.properties.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SearchPlaceForGeo.propTypes = {
  onSelect: PropTypes.func.isRequired,
  setOpenList: PropTypes.func.isRequired,
  openList: PropTypes.string
};

export default SearchPlaceForGeo;

import axios from 'axios';

export const getSuggestionsFromDataGouv = async term => {
  try {
    const response = await axios.get('https://api-adresse.data.gouv.fr/search/', {
      params: {
        q: term,
        limit: 5
      }
    });
    return response.data.features;
  } catch (error) {
    console.error('Erreur lors de la récupération des suggestions :', error);
    return [];
  }
};

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

// Composant pour recentrer la carte dynamiquement
const RecenterAutomatically = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    if (center) {
      map.setView(center, 12);
    }
  }, [center, map]);

  return null;
};

RecenterAutomatically.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default RecenterAutomatically;

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.css';

const NotFound = () => {
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.notFoundContent}>
        <div className="page-content">
          <h1>404 - Page non trouvée</h1>
          <p>Oups ! La page que vous cherchez n&apos;existe pas ou a été déplacée.</p>
          <img src="/404-illustration.png" alt="Page non trouvée" className={styles.notFoundImage} />
          <Link to="/" className={styles.backHomeButton}>
            Retour à la page d&apos;accueil
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { postParticulier } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

const AddParticulierForm = ({ onSubmit }) => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const newParticulier = { nom, prenom };
    const savedParticulier = await postParticulier(newParticulier);
    onSubmit(prev => [...prev, savedParticulier]);
    setNom('');
    setPrenom('');
  };

  return (
    <form className={styles.addParticulierForm} onSubmit={handleSubmit}>
      <div>
        <label>Nom :</label>
        <input type="text" value={nom} onChange={e => setNom(e.target.value)} required />
      </div>
      <div>
        <label>Prénom :</label>
        <input type="text" value={prenom} onChange={e => setPrenom(e.target.value)} required />
      </div>
      <button className={styles.buttonList} type="submit">
        Ajouter
      </button>
    </form>
  );
};

AddParticulierForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default AddParticulierForm;
